import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const MoneyIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMoneyIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <g fill="none" stroke="#4a4a4a" strokeLinecap="round" strokeLinejoin="round">
        <rect x={0.5} y={6.5} width={13} height={7} rx={1} />
        <path d="M3.5 2v2M7 .5V4M10.5 2v2" />
        <circle cx={7} cy={10} r={1.5} />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

MoneyIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MoneyIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MoneyIcon;
