import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const LaptopIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootLaptopIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <g fill="none" stroke="#4a4a4a" strokeLinecap="round" strokeLinejoin="round">
        <path d="M2.5 8 .72 10.55a1 1 0 0 0 .05 1 1 1 0 0 0 .85.47h10.76a1 1 0 0 0 .85-.47 1 1 0 0 0 0-1L11.5 8M3.5 2a1 1 0 0 0-1 1v5h9V3a1 1 0 0 0-1-1Z" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

LaptopIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

LaptopIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default LaptopIcon;
