import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const UnlockIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootUnlockIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="24"
      width="24"
    >
      <g fill="none" stroke="#4a4a4a" strokeLinecap="round" strokeLinejoin="round">
        <rect x="2" y="5.5" width="10" height="8" rx="1" />
        <path d="M9.47 1.53A3.44 3.44 0 0 0 7 .5 3.5 3.5 0 0 0 3.5 4v1.5" />
        <circle cx="7" cy="9.5" r="0.5" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

UnlockIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

UnlockIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default UnlockIcon;
