import React from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const DescriptionModal = props => {
  const {
    containerClassName,
    contentClassName,
    id,
    isOpen,
    description,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  return (
    <Modal
      id={id}
      containerClassName={containerClassName}
      contentClassName={contentClassName}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="ListingPage.descriptionModalTitle" />
      </h1>
      <p className={css.modalMessage}>{description}</p>
    </Modal>
  );
};

export default DescriptionModal;
