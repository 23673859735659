import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import UnlockIcon from './UnlockIcon';
import MoneyIcon from './MoneyIcon';
import LaptopIcon from './LaptopIcon';
import css from './ListingPage.module.css';

const experienceItem = (title, text, icon) => {
  return (
    <div className={css.experiencesFeature}>
      {icon}
      <div>
        <div className={css.experiencesFeatureHeading}>{title}</div>
        <p className={css.experiencesFeatureText}>{text}</p>
      </div>
    </div>
  );
};

const SectionSubmitExperiencesMaybe = props => {
  const {
    intl,
    listingId,
    title,
    hasExperience,
    hasUsers,
    isOwnListing,
    isOwnListingVariant,
  } = props;

  // Show the section if there isn't an experience
  // connected with this show page.
  const showSection = !isOwnListingVariant && isOwnListing && !hasExperience;

  // Edit params for creating a new experience page.
  const editParams = {
    slug: 'draft',
    id: '00000000-0000-0000-0000-000000000000',
    type: 'new',
    tab: 'details',
  };

  const contentClasses = classNames(css.submitExperiencesContent, {
    [css.submitExperiencesContentBorder]: hasUsers,
  });
  return showSection ? (
    <div className={css.sectionSubmitExperiences}>
      <div className={contentClasses}>
        <div className={css.experiencesFeatures}>
          {experienceItem(
            intl.formatMessage({
              id: 'ListingPage.unlockHeading',
            }),
            intl.formatMessage({
              id: 'ListingPage.unlockText',
            }),
            <UnlockIcon className={css.experiencesFeatureIcon} />
          )}
          {experienceItem(
            intl.formatMessage({
              id: 'ListingPage.earnHeading',
            }),
            intl.formatMessage({
              id: 'ListingPage.earnText',
            }),
            <MoneyIcon className={css.experiencesFeatureIcon} />
          )}
          {experienceItem(
            intl.formatMessage({
              id: 'ListingPage.startHeading',
            }),
            intl.formatMessage({
              id: 'ListingPage.startText',
            }),
            <LaptopIcon className={css.experiencesFeatureIcon} />
          )}
        </div>
        <NamedLink
          className={css.submitExperienceLink}
          name="EditExperiencePage"
          params={editParams}
          to={{
            state: { showId: listingId.uuid },
          }}
        >
          <FormattedMessage id="ListingPage.submitExperienceLink" values={{ showTitle: title }} />
        </NamedLink>
      </div>
    </div>
  ) : null;
};

export default SectionSubmitExperiencesMaybe;
