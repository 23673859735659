import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews, SecondaryButton } from '../../components';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const MAX_VISIBLE_REVIEWS = 4;

const useReviews = (reviews, isMobileLayout) => {
  const [maxVisibleReviews, setMaxVisibleReviews] = useState(MAX_VISIBLE_REVIEWS);

  useEffect(() => {
    setMaxVisibleReviews(isMobileLayout ? reviews.length : MAX_VISIBLE_REVIEWS);
  }, [isMobileLayout]);

  return [maxVisibleReviews, setMaxVisibleReviews];
};

const SectionReviewsMaybe = props => {
  const { reviews, fetchReviewsError, isMobileLayout } = props;

  const [maxVisibleReviews, setMaxVisibleReviews] = useReviews(reviews, isMobileLayout);

  // Make sure to only show the section if there's reviews
  const showSection = reviews.length > 0;

  const showMoreReviewsButton =
    reviews.length > maxVisibleReviews ? (
      <SecondaryButton
        className={css.showMoreReviews}
        onClick={() => setMaxVisibleReviews(reviews.length)}
      >
        <FormattedMessage id="ListingPage.showMoreReviews" values={{ count: reviews.length }} />
      </SecondaryButton>
    ) : null;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  const classes = classNames(css.sectionReviews, {
    [css.sectionReviewsWithButton]: showMoreReviewsButton,
  });

  return showSection ? (
    <div className={classes}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ListingPage.reviewsHeading" values={{ count: reviews.length }} />
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <Reviews reviews={reviews.slice(0, maxVisibleReviews)} />
      {showMoreReviewsButton}
    </div>
  ) : null;
};

export default SectionReviewsMaybe;
