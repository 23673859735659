import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  encodeLatLngBounds,
} from '../../util/urlHelpers';
import { findOptionsForSelectFilter, findConfigOptionForSelectFilter } from '../../util/search';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import {
  calculateAverageReview,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { followUser, unfollowUser } from '../../ducks/user.duck';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ShowPanel,
  ShareModal,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import moment from 'moment';

// Sections
import SectionImages from './SectionImages';
import SectionHighlights from './SectionHighlights';
import SectionSubmitExperiencesMaybe from './SectionSubmitExperiencesMaybe';
import SectionExperiencesMaybe from './SectionExperiencesMaybe';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionReviewsMaybe from './SectionReviewsMaybe';
import SectionHostsMaybe from './SectionHostsMaybe';
// Modals
import SuccessPostingModal from './SuccessPostingModal';
import DescriptionModal from './DescriptionModal';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }

  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      successPostingModalOpen: false,
      descriptionModalOpen: false,
      shareModalOpen: false,
    };

    this.onLocationSearch = this.onLocationSearch.bind(this);
  }

  onLocationSearch() {
    const { params, history, getListing } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const routes = routeConfiguration();

    const { geolocation, publicData } = listing.attributes;
    const address = publicData.location.address;

    const LatLng = { lat: geolocation.lat, lng: geolocation.lng };
    const bounds = locationBounds(LatLng, config.maps.search.currentLocationBoundsDistance);

    return history.push(
      createResourceLocatorString(
        'SearchPage',
        routes,
        {},
        {
          bounds: encodeLatLngBounds(bounds),
          address,
        }
      )
    );
  }

  componentDidMount() {
    const { location } = this.props;
    const finishedListingCreation = location?.state?.finishedListingCreation;

    if (finishedListingCreation) {
      this.setState({
        successPostingModalOpen: true,
      });
    }
  }

  render() {
    const {
      intl,
      params: rawParams,
      location,
      filterConfig,
      // UI
      scrollingDisabled,
      onManageDisableScrolling,
      viewport,
      // Listing
      getListing,
      getOwnListing,
      showListingInProgress,
      showListingError,
      // user(s)
      currentUser,
      users,
      fetchUsersInProgress,
      fetchUsersError,
      hasUsers,
      onFollowUser,
      onUnfollowUser,
      followUserInProgress,
      followUserError,
      unfollowUserInProgress,
      unfollowUserError,
      // reviews
      reviews,
      fetchReviewsError,
      // experience(s)
      hasExperience,
      experiences,
      searchExperiencesInProgress,
      searchExperiencesError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'tickets' : 'basic';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const { description = '', price = null, title = '', publicData } = currentListing.attributes;

    // filter options from marketplace-custom-config.js
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const lengthOptions = findOptionsForSelectFilter('length', filterConfig);
    const ageOptions = findOptionsForSelectFilter('age', filterConfig);
    const statusOptions = findOptionsForSelectFilter('status', filterConfig);

    // find option from array of options
    const categoryConfig = findConfigOptionForSelectFilter(categoryOptions, publicData?.category);
    const lengthConfig = findConfigOptionForSelectFilter(lengthOptions, publicData?.length);
    const ageConfig = findConfigOptionForSelectFilter(ageOptions, publicData?.age);
    const statusConfig = findConfigOptionForSelectFilter(statusOptions, publicData?.status);

    // option label & publicData value
    const category = categoryConfig?.label;
    const length = lengthConfig?.label;
    const age = ageConfig?.label;
    const status = statusConfig?.label;
    const ticketLink = publicData?.ticketLink;
    const address = publicData?.location?.address;
    const coverId = publicData?.coverId;

    // dates
    const { start, end } = publicData?.dates || {};

    const startDate = moment(start).format('MMM DD');
    const endDate = moment(end).format('MMM DD');

    const endDateYear = moment(end).year();
    const showDate = `${startDate} - ${endDate}, ${endDateYear}`;

    // listing fields object
    const listingFields = {
      description,
      category,
      length,
      age,
      status,
      address,
      ticketLink,
      showDate,
    };

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const topbar = (
      <TopbarContainer desktopClassName={css.topbarDesktop} contentClassName={css.topbarContent} />
    );

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const isOwnListingVariant = isDraftVariant || isPendingApprovalVariant;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled} currentPage="ListingPage">
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer currentPage="ListingPage" />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled} currentPage="ListingPage">
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer currentPage="ListingPage" />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice } = priceData(price, intl);

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const averageReview = calculateAverageReview(reviews);

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        currentPage="ListingPage"
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar className={css.pageTopbar}>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionImages
                title={title}
                richTitle={richTitle}
                listing={currentListing}
                showListingInProgress={showListingInProgress}
                currentUser={currentUser}
                currentAuthor={currentAuthor}
                authorDisplayName={authorDisplayName}
                reviews={reviews}
                averageReview={averageReview}
                organizationProfile={publicData?.organizationProfile}
                coverId={coverId}
                isOwnListing={isOwnListing}
                imageCarouselOpen={this.state.imageCarouselOpen}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                followUserInProgress={followUserInProgress}
                followUserError={followUserError}
                unfollowInProgress={unfollowUserInProgress}
                unfollowError={unfollowUserError}
                handleViewPhotosClick={handleViewPhotosClick}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                onOpenShareModal={() => this.setState({ shareModalOpen: true })}
                onFollowUser={onFollowUser}
                onUnfollowUser={onUnfollowUser}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.contentContainer}>
                <div className={css.highlightsAndPanelSections}>
                  <SectionHighlights
                    intl={intl}
                    publicData={publicData}
                    isMobileLayout={isMobileLayout}
                  />
                  <ShowPanel
                    className={css.showPanel}
                    listing={currentListing}
                    showListingInProgress={showListingInProgress}
                    listingFields={listingFields}
                    isOwnListing={isOwnListing}
                    onLocationSearch={this.onLocationSearch}
                    onOpenDescriptionModal={() => this.setState({ descriptionModalOpen: true })}
                  />
                </div>
                <SectionDescriptionMaybe description={description} />
                <SectionSubmitExperiencesMaybe
                  intl={intl}
                  listingId={listingId}
                  title={title}
                  hasExperience={hasExperience}
                  hasUsers={hasUsers}
                  isOwnListing={isOwnListing}
                  isOwnListingVariant={isOwnListingVariant}
                />
                <SectionExperiencesMaybe
                  intl={intl}
                  experiences={experiences}
                  searchExperiencesInProgress={searchExperiencesInProgress}
                  searchExperiencesError={searchExperiencesError}
                />
                <SectionReviewsMaybe
                  reviews={reviews}
                  fetchReviewsError={fetchReviewsError}
                  isMobileLayout={isMobileLayout}
                />
                <SectionHostsMaybe
                  intl={intl}
                  users={users}
                  fetchUsersInProgress={fetchUsersInProgress}
                  fetchUsersError={fetchUsersError}
                />
              </div>
            </div>
            <ShareModal
              id="ListingPage.shareModal"
              location={location}
              isOpen={this.state.shareModalOpen}
              onCloseModal={() => this.setState({ shareModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <SuccessPostingModal
              id="ListingPage.successPostingModal"
              containerClassName={css.descriptionModalContainer}
              contentClassName={css.modalContent}
              listingId={listingId}
              isOpen={this.state.successPostingModalOpen}
              onCloseModal={() => this.setState({ successPostingModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <DescriptionModal
              id="ListingPage.descriptionModal"
              containerClassName={css.modalContainer}
              contentClassName={css.modalContent}
              isOpen={this.state.descriptionModalOpen}
              description={description}
              onCloseModal={() => this.setState({ descriptionModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="ListingPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  // listing
  showListingInProgress: false,
  showListingError: null,

  // user(s)
  isAuthenticated: false,
  currentUser: null,
  users: [],
  fetchUsersInProgress: false,
  fetchUsersError: null,
  hasUsers: false,
  onFollowUser: null,
  onUnfollowUser: null,
  followUserInProgress: false,
  followUserError: null,
  unfollowUserInProgress: false,
  unfollowUserError: null,

  // reviews
  reviews: [],
  fetchReviewsError: null,

  // experience(s)
  hasExperience: false,
  experiences: [],
  searchExperiencesInProgress: false,
  searchExperiencesError: null,

  filterConfig: config.custom.filters,
};

ListingPageComponent.propTypes = {
  // UI
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,

  // listing
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  showListingError: propTypes.error,

  // reviews
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,

  // user(s)
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  users: arrayOf(propTypes.user),
  fetchUsersInProgress: bool.isRequired,
  fetchUsersError: propTypes.error,
  hasUsers: bool.isRequired,
  onFollowUser: func.isRequired,
  onUnfollowUser: func.isRequired,
  followUserInProgress: bool.isRequired,
  followUserError: propTypes.error,
  unfollowUserInProgress: bool.isRequired,
  unfollowUserError: propTypes.error,

  // experience(s)
  hasExperience: bool.isRequired,
  experiences: arrayOf(propTypes.listing),
  searchExperiencesInProgress: bool.isRequired,
  searchExperiencesError: propTypes.error,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,
  filterConfig: array,

  // for CheckoutPage
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    showListingInProgress,
    reviews,
    fetchReviewsError,
    userIds,
    fetchUsersInProgress,
    fetchUsersError,
    hasExperience,
    searchExperienceIds,
    searchExperiencesInProgress,
    searchExperiencesError,
  } = state.ListingPage;
  const {
    currentUser,
    followUserInProgress,
    followUserError,
    unfollowUserInProgress,
    unfollowUserError,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const userEntities = userIds.map(userId => ({ type: 'user', id: userId }));
  const users = getMarketplaceEntities(state, userEntities);
  const hasUsers = users.length > 0;

  return {
    isAuthenticated,
    currentUser,
    followUserInProgress,
    followUserError,
    unfollowUserInProgress,
    unfollowUserError,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingInProgress,
    showListingError,
    reviews,
    fetchReviewsError,
    users,
    fetchUsersInProgress,
    fetchUsersError,
    hasUsers,
    hasExperience,
    experiences: searchExperienceIds.map(id => getListing(id)),
    searchExperiencesInProgress,
    searchExperiencesError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFollowUser: followUserId => dispatch(followUser(followUserId)),
  onUnfollowUser: unfollowUserId => dispatch(unfollowUser(unfollowUserId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
