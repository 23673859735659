import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, AvatarLarge, Slider } from '../../components';

import css from './ListingPage.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3.2;
const SLIDES_TO_SHOW_DESKTOP = 5;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 1.2;
const SLIDES_TO_SCROLL_DESKTOP = 2;

const HostCard = ({ user }) => {
  const { displayName } = user.attributes.profile;
  return (
    <NamedLink className={css.host} name="ProfilePage" params={{ id: user.id.uuid }}>
      <AvatarLarge className={css.hostAvatar} user={user} disableProfileLink />
      <h3 className={css.hostHeading}>{displayName}</h3>
      <span className={css.hostCategory}>Balance</span>
    </NamedLink>
  );
};

const SectionHostsMaybe = props => {
  const { intl, users, fetchUsersInProgress, fetchUsersError } = props;

  if (fetchUsersInProgress || users.length === 0) {
    return null;
  }

  const fetchError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.fetchHostsError" />
    </h2>
  );

  const sliderTitle = intl.formatMessage({ id: 'ListingPage.hostsHeading' });

  return (
    <div className={css.sectionHostsMaybe}>
      {fetchUsersError ? fetchError : null}
      <div className={css.hosts}>
        <Slider
          id="HostsSlider"
          headerClassName={css.sliderHeader}
          titleClassName={css.hostsTitle}
          title={sliderTitle}
          slidesToShow={{
            tablet: SLIDES_TO_SHOW_TABLET,
            desktop: SLIDES_TO_SHOW_DESKTOP,
          }}
          slidesToScroll={{
            tablet: SLIDES_TO_SCROLL_TABLET,
            desktop: SLIDES_TO_SCROLL_DESKTOP,
          }}
        >
          {users.map(u => {
            return <HostCard key={u.id.uuid} user={u} />;
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SectionHostsMaybe;
